.App {
  height: 100%;
  color: white;
  font-family: Georgia;
  padding: 10px;
  padding-top: 50px;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.break {
  flex-basis: 100%;
  height: 0;
}

.top-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.icons{
  margin-bottom: 20px;
}

.terminal{
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Name{
  font-size: 36px;
  margin-top: 7px;
  text-align: center;
}

.bullets{
  font-size: 16px;
  justify-content: flex-start;
}

ul {
  list-style-type: '➢ ';
}

li {
  padding: 2px;
}

.section-title{
  font-size: 36px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.card {
  /* background: #fff; */
  width: 24em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  margin-left: 200px;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  width: 100%;
  object-fit: cover;
}

.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  /* font-family: 'Montserrat', sans-serif; */
  transition: all ease-in 100ms;
}

.card p {
  color: #777;
}

.card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.card-body {
  padding: 1.2em;
}